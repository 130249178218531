import { Fragment, type FC } from 'react'
import classNames from 'classnames'
import { Slide } from 'react-awesome-reveal'
import { Text, ImageHeading } from 'components'
import { AboutCenterImage, AboutUs2, AboutUs3, AbouteUs1 } from 'assets'

import styles from './AboutUsPage.module.scss'

const AboutUsPage: FC = () => (
  <Fragment>
    <ImageHeading
      title='aboutUs'
      subTitle='Մեր առաքելությունը՝ կատարելություն, վստահություն և արժեքներ'
      description='Մենք կիսում ենք մեր պատմությունը և նպատակները, որպեսզի հասկանաք մեր նվիրվածությունը կատարելությանը, վստահությունը մեր գործընկերներին և արժեքները, որոնք մեզ առաջնորդում են ամեն քայլափոխին: Մենք այստեղ ենք՝ ստեղծելու նորարարական լուծումներ, որոնք կբարելավեն ձեր կյանքը:'
    />
    <div className={classNames('container', styles.wrapper_top)}>
      <div className={styles.wrapper_top__left}>
        <img src={AbouteUs1} className={styles.wrapper_top__left__1} />
        <img src={AboutUs2} className={styles.wrapper_top__left__2} />
        <img src={AboutUs3} className={styles.wrapper_top__left__3} />
        <img src={AboutUs3} className={styles.wrapper_top__left__4} />
      </div>
      <div className={styles.wrapper_info}>
        <Slide direction={'right'} triggerOnce>
          <Text className={styles.wrapper_info_sub_title} text='Գործունեություն' />
        </Slide>
        <Slide direction={'right'} triggerOnce>
          <Text
            className={styles.wrapper_info_sub_description}
            text={
              'ԿԱՆՍԱԼՈՒՏ-ը հայկական շուկայի առաջատար ինժեներական ընկերություններից է, որը\n' +
              'հաջողությամբ գործում է Հայաստանյան շուկայում ավելի քան 10 տարի:\n' +
              'Ընկերության նախագծման բաժինը կազմված է բարձր որակավորում ունեցող, ՀՀ- ում հավաստագրված մասնագետներից, ովքեր ունեն երկար տարիների փորձ։ Թիմային աշխատանքի շնորհիվ  մեր մասնագետները կարողանում են հնարավորինս սեղմ ժամկետներում բավարարել հաճախորդների ամենաբարձր պահանջները: Նախագծողներն իրենց աշխատանքը կատարում են նորարարական տեխնոլոգիաների և ծրագրային ապահովումների միջոցով։'
            }
          />
        </Slide>
      </div>
    </div>
    <div className={classNames('container', styles.wrapper_center)}>
      <div className={styles.wrapper_info}>
        <Slide direction={'right'} triggerOnce>
          <Text
            className={styles.wrapper_info_sub_title}
            text='Մենք պատրաստ ենք ձեզ տրամադրել ինժեներական ծառայությունների ամբողջական ցիկլ'
          />
        </Slide>
        <Slide direction={'left'} triggerOnce>
          <ul className={styles.wrapper_info_sub_description__list}>
            <li className={styles.wrapper_info_sub_description__list__item}>
              <Text className={styles.wrapper_info_sub_description} text={`Ինժեներական խորհրդատվություն`} />
            </li>
            <li className={styles.wrapper_info_sub_description__list__item}>
              <Text
                className={styles.wrapper_info_sub_description}
                text={`Նախնական և աշխատանքային նախագծերի մշակում`}
              />
            </li>
            <li className={styles.wrapper_info_sub_description__list__item}>
              <Text
                className={styles.wrapper_info_sub_description}
                text={`Ինժեներական համակարգերի մատակարարում և հավաքակցում`}
              />
            </li>
            <li className={styles.wrapper_info_sub_description__list__item}>
              <Text className={styles.wrapper_info_sub_description} text={`Երաշխիքային և հետերաշխիքային սպասարկում`} />
            </li>
            <li className={styles.wrapper_info_sub_description__list__item}>
              <Text
                className={styles.wrapper_info_sub_description}
                text={`Գոյություն ունեցող համակարգերի ախտորոշիչ ստուգում և մոդեռնիզացում`}
              />
            </li>
          </ul>
        </Slide>
      </div>

      <img src={AboutCenterImage} className={styles.wrapper_center_image} />
    </div>

    {/*<div className={classNames('container', styles.wrapper_bottom)}>*/}
    {/*  <Slide direction={'right'} triggerOnce className={styles.wrapper_bottom_card}>*/}
    {/*    <AboutUsCards src={AboutUsFoot1} name='What is Lorem Ipsum? ' />*/}
    {/*  </Slide>*/}
    {/*  <Slide direction={'right'} triggerOnce className={styles.wrapper_bottom_card}>*/}
    {/*    <AboutUsCards src={AboutUsFoot2} name='What is Lorem Ipsum? ' />*/}
    {/*  </Slide>*/}
    {/*  <Slide direction={'right'} triggerOnce className={styles.wrapper_bottom_card}>*/}
    {/*    <AboutUsCards src={AboutUsFoot3} name='What is Lorem Ipsum? ' />*/}
    {/*  </Slide>*/}
    {/*</div>*/}
  </Fragment>
)

export default AboutUsPage
