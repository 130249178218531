import { FC, useState } from 'react'

import styles from './SelectLanguage.module.scss'
import { useLanguageTranslation } from 'hooks'
import { getCookie } from 'libraries/cookie'
import { Text } from '../index'

type TLangProps = {
  id: number | null
  lng: string
  value: string
}

const initialState: TLangProps = { id: null, lng: '', value: '' }

const languageList: TLangProps[] = [
  { id: 2, lng: 'am', value: 'am' },
  { id: 0, lng: 'en', value: 'en' },
  { id: 1, lng: 'ru', value: 'ru' },
]

const SelectLanguage: FC = () => {
  const { onLanguageChangeCallback } = useLanguageTranslation()

  const getCookieLanguage = getCookie('i18next')
  console.log('getCookieLanguage', getCookieLanguage)
  const isDetect = languageList.find(language => language.value === getCookieLanguage)

  const [open, setOpen] = useState<boolean>(false)
  const [activeLanguage, setActiveLanguage] = useState<TLangProps | undefined>(isDetect)

  const handleLanguageChange = (ID: number | null, value: string) => {
    setActiveLanguage(languageList.find(item => item.id === ID) || initialState)
    onLanguageChangeCallback(value)
    setOpen(false)
  }

  const openHandler = () => {
    setOpen(!open)
  }

  const renderLanguageList = languageList.map(item => (
    <span
      key={item.id}
      onClick={() => handleLanguageChange(item.id, item.value)}
      className={styles.language__list_item}
    >
      <Text text={item.lng} />
    </span>
  ))

  return (
    <div className={styles.language} role='button' onClick={openHandler}>
      <Text text={languageList[activeLanguage?.id || 0]?.lng} />
      {/* Closing this because we have only armenian */}
      {/*{open && <div className={styles.language__list}>{renderLanguageList}</div>}*/}
      {false && <div className={styles.language__list}>{renderLanguageList}</div>}
    </div>
  )
}

export default SelectLanguage
