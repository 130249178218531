export const data = [
  {
    title: 'projecting',
    description: 'projectingText',
    image: 'https://kansolut.am/assets/renderForest.jpg',
  },
  {
    title: 'installation',
    description: 'installationText',
    image: 'https://kansolut.am/assets/installation.jpg',
  },
  {
    title: 'bim',
    description: `bimText`,
    image: 'https://kansolut.am/assets/bim.jpg',
  },
]
