import { Fragment, type FC, useState, useMemo, useCallback } from 'react'
import { List, Tabs } from 'antd'

import { portfolioCardList, tabs } from './utils'

import { PortfolioCard } from 'components'
import { BlockImageComponent } from 'components'
import ImageHeading from '../../components/ImageHeading'

import { PortfolioBlockImage } from 'assets'

import styles from './Portfolio.module.scss'

import { TPortfolioCardProps } from '../../components/PortfolioCard'
import classNames from 'classnames'

const Portfolio: FC = () => {
  const [activeTab, setActiveTav] = useState(tabs[0]?.key)

  const data = useMemo(
    () => portfolioCardList.filter(item => activeTab === 'all' || item?.type === activeTab),
    [activeTab]
  )

  const renderItem = useCallback(
    ({ date, title, backgroundImage }: TPortfolioCardProps, index: number) => (
      <>
        <List.Item>
          <PortfolioCard date={date} title={title} backgroundImage={backgroundImage} />
        </List.Item>
        {/* We are not planning to put more than 2 image blocks inside the list, that is the reason for this code. */}
        {activeTab === 'all' && index === 5 && data?.length > 6 && (
          <BlockImageComponent
            backgroundImage={PortfolioBlockImage}
            title='Welcome to KANSOLUT, where vision meets structure'
            description='At KANSOLUT, we specialize in bringing architectural visions to life through our comprehensive range of construction services. With a dedication to quality craftsmanship, innovation, and client satisfaction, we have established ourselves as a leader in the construction industry. '
          />
        )}
        {activeTab === 'all' && index === 17 && data?.length > 18 && (
          <BlockImageComponent
            backgroundImage={PortfolioBlockImage}
            title='Welcome to KANSOLUT, where vision meets structure'
            description='At KANSOLUT, we specialize in bringing architectural visions to life through our comprehensive range of construction services. With a dedication to quality craftsmanship, innovation, and client satisfaction, we have established ourselves as a leader in the construction industry. '
          />
        )}
      </>
    ),
    [activeTab, data?.length]
  )

  return (
    <Fragment>
      <ImageHeading
        title='portfolio'
        subTitle='Բացահայտեք մեր աշխատանքներն ու հաջողությունները'
        description='Մեր պորտֆոլիոն ներկայացնում է մեր նվիրվածությունը գերազանցությանը և նորարարությանը: Այստեղ դուք կգտնեք մեր ավարտած նախագծերը, որոնք ցույց են տալիս մեր հմտություններն ու կարողությունները:'
      />
      <div className={styles.tab__wrapper}>
        <Tabs items={tabs} onChange={setActiveTav} />
      </div>
      <div className={classNames('container', styles.list__wrapper)}>
        <List
          dataSource={data}
          renderItem={renderItem}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
        />
      </div>
    </Fragment>
  )
}

export default Portfolio
