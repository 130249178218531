export const portfolioCardList = [
  {
    type: 'design',
    backgroundImage: 'https://kansolut.am/assets/portfolio/0.jpg',
    title: 'Դարգեթ',
    date: '2023',
  },
  {
    type: 'design',
    backgroundImage: 'https://kansolut.am/assets/portfolio/1.jpg',
    title: 'ՌենդեռՖոռեսթ',
    date: '2023',
  },
  {
    type: 'bim',
    backgroundImage: 'https://kansolut.am/assets/portfolio/2.jpg',
    title: 'Առանձնատուն',
    date: '2023',
  },
  {
    type: 'design',
    backgroundImage: 'https://kansolut.am/assets/portfolio/3.png',
    title: 'COAF',
    date: '2023',
  },
  {
    type: 'installation',
    backgroundImage: 'https://kansolut.am/assets/portfolio/4.jpg',
    title: 'Ազատություն 26/1',
    date: '2023',
  },
]

export const tabs = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: 'design',
    label: 'Design works',
  },
  {
    key: 'bim',
    label: 'BIM modeling',
  },
  {
    key: 'installation',
    label: 'Installation work',
  },
]
